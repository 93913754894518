<template>
    <v-card>
      <v-card-title>Janet McCrorie</v-card-title>
      <v-card-text>
        <p>Shop the new range of Janet McCrorie open edition prints and products, including our brand-new ceramic and melamine coasters, glass chopping boards, wood panel boards, framed canvas, and framed tiles.</p>
        <v-card class="mb-2" elevation="2">
        <v-card-text class="d-flex align-center">
          <div class="mr-4">
            <h3 class="mb-2 primary--text">Limited Time Offer</h3>
            <h3>Get 10% off all new Janet McCrorie UV products until the end of August. Use code JM2408 during checkout or over the phone.</h3>
          </div>
          <v-spacer></v-spacer>
          <v-btn
              color="success"
              @click="goToProducts"
            >
              Browse These Products
          </v-btn>
        </v-card-text>
        <v-card-text>
          <v-row justify="center">
          <v-col v-for="(product, index) in products" xl="4" lg="4" sm="6" cols="12" :key="index">
            <v-sheet elevation="3">
              <v-img class="v-clickable" @click="goToProducts" :src="getImage(product.image)"></v-img>
            </v-sheet>
            <h5 class="mt-2 text-center">{{product.category}}</h5>
          </v-col>
        </v-row>
        </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

</template>

<script>
import ClickApi from "@/api/public/click";

export default {

  data: () => ({
    timeStamp: new Date().getTime(),
    imageUrl: `${process.env.VUE_APP_IMG_URL}/300px/`,
    products: [
      { category: "Coasters", image: 'Coasters.jpg' },
      { category: "Framed Tiles", image: 'Framed-Tiles.jpg' },
      { category: "Glass Chopping Boards", image: 'Glass-Chopping-Boards.jpg' },
      { category: "Stretched Canvases (Framed)", image: 'Stretched-Canvases---Framed.jpg' },
      { category: "Wood Panel Boards", image: 'Wood-Panel-Boards.jpg' }
    ]
  }),
  computed: {
    activeComponent () {
      return this.$route.params.token
    }
  },
  async created() {
    await ClickApi.create({name: this.activeComponent, timeStamp: this.timeStamp, value: 'Marketing landing page accessed' })
    const obj = {category: [
        "Coaster",
        "Framed Tile",
        "Glass Chopping Board",
        "Stretched Canvas (Framed)",
        "Wood Panel Board"
      ]}
      this.$store.commit('filters/resetFilters')
      this.$store.commit('filters/setFilters', obj)
  },
  methods: {
    getImage (image) {
      return require('@/assets/' + image)
    },
    async goToProducts() {
      await ClickApi.create({name: this.activeComponent, timeStamp: this.timeStamp, value: 'CTA clicked from marketing page' })
      this.$router.push('/catalog').catch((err) => {
        return err !== null
      })
    },
  },
};
</script>
